import Vue from 'vue'
import App from './App.vue'
import router from './router'
import "./assets/scss/global.scss"
import { ValidationObserver, ValidationProvider, extend, localize } from 'vee-validate';
import en from 'vee-validate/dist/locale/en.json';
import * as rules from 'vee-validate/dist/rules';

// install rules and localization
Object.keys(rules).forEach(rule => {
    extend(rule, rules[rule]);
});

const dict = {
    en: {
        messages: en.messages,
        names: {
            email: "Email Address",
            phone: "Phone Number"
        },
        fields: {
            phone: {
                required: "Please enter a phone number",
                alpha: "Please enter a valid phone number"
            },
            email: {
                required: "Please enter an Email Address",
                alpha: "Please enter a valid Email Address"
            },
            from:{
                required: "Please enter your name"
            },
            totwitter:{
                required: "This field is required"
            },
            assets:{
                required: "Please enter an Asset ID"
            },
        }
    },
};
localize(dict);

Vue.use(require('vue-moment'));

// Install components globally
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);
Vue.config.productionTip = false

var filter = function(text, length, clamp){
    //clamp = clamp || '...';
    var node = document.createElement('div');
    node.innerHTML = text;
    var content = node.textContent.toUpperCase();
    return content.length > length ? content.slice(0, length) + clamp : content;
};

Vue.filter('truncate', filter);

window.vueInstance = new Vue({
    router,
    render: h => h(App),
}).$mount('#app')

window.twitterOAuthCallback = function(oAuthData){
    //console.log(oAuthData)
    window.vueInstance.$children[0].$refs.templateData.updateTwitterData(oAuthData)
}
window.googleTranslateElementInit = function(){
    new window.google.translate.TranslateElement(
        { autoDisplay: true, includedLanguages: 'en,es,pt-PT,zh-CN,ar' },
        'google_translate_element'
    );
}